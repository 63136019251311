var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-tips-contain" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "", "show-close": false, visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c("div", { staticClass: "dialog-contain" }, [
            _c("div", { staticClass: "header-contain" }, [
              _c(
                "button",
                {
                  staticClass: "el-dialog__headerbtn",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: { click: _vm.closeClick },
                },
                [
                  _c("i", {
                    staticClass: "el-dialog__close el-icon el-icon-close",
                    staticStyle: { color: "#909399" },
                  }),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "dialog-body-contain" },
              [
                _vm._t("body", function () {
                  return [
                    _c("div", { staticClass: "tips-contain" }, [
                      _c(
                        "div",
                        { staticClass: "icon-contain" },
                        [
                          _c("en-icon", {
                            staticClass: "icon",
                            style: {
                              fill:
                                _vm.dialogType === "message"
                                  ? "#F76B6B"
                                  : "#3e90fe",
                            },
                            attrs: { size: "normal", name: _vm.iconName },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "text-contain" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.messageShow,
                                expression: "messageShow",
                              },
                            ],
                            staticClass: "message",
                          },
                          [_vm._v(_vm._s(_vm.message))]
                        ),
                      ]),
                    ]),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "dialog-bottom-contain" },
              [
                _vm._t("footer", function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "button-contain" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "button",
                            style: _vm.messageShow ? _vm.buttonStyle : "",
                            attrs: { size: "mini" },
                            on: { click: _vm.confirmClick },
                          },
                          [_vm._v(" 确定 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }